import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { useEffect } from "react"
import { graphql } from "gatsby"

export default function Opinie({ data }) {
  const seo = {
    title: "Opinie Pacjentów | Medycyna Estetyczna Szczecin - Pastucha",
    description: "OPINIE PACJENTÓW: plastyka powiek, powiększanie ust, likwidacja zmarszczek, powiększanie / podniesienie piersi, plastyka brzucha...",
    url: "https://mpastucha.pl/opinie"
  }

  const testimonials = data?.testimonials?.edges?.map(({ node }) => node)


  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.OpinionPageInit && window.$) {
        clearInterval(interval);
        window.OpinionPageInit();
      }
    }, 50)
  });

  return (
    <Layout headerClasses={'mainheader-subpage mainheader-white'} classes={'bg-creme'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img src="/images/phone.svg" alt="Ikona telefon" /></a>
      <div className="fullwidth"> <img src="/uploads/2015/09/opinions-1920x730-1920x730-c-default.jpg" alt="chirurgia estetyczna Szczecin Maciej Pastucha" /></div>
      <section className="sct container">
        <div className="row">
          <div className="col-10sm-center">
            <div className="row">
              <div className="col-12sm" style={{margin: '0 15px'}}>
                <h1>Opinie</h1>
              </div>
              { testimonials.map((testimonial, key) => (
                <div className="col-6sm opinion-listing" key={key}>
                  <div className="opinion">
                    <h4>{testimonial.procedure}</h4>
                    <p className="minimize" dangerouslySetInnerHTML={{__html: testimonial.content}} />
                  </div>
                  <div className="txt-al-r txt-small">
                    <p>~{testimonial.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>


    </Layout>
  )
}

export const pageQuery = graphql`
    query Testimonials {
        testimonials: allTestimonialsYaml {
            edges {
                node {
                    slug
                    procedure
                    content
                    name
                }
            }
        }
    }`
